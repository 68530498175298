<template>
  <div class="role">
    <div class="left-menus" v-if="adminId == 1">
      <div class="left-list">
        <div :class="enpId == item.id ? 'left-enp-item-active' : 'left-enp-item'" v-for="item in companyList"
          :key="item.id" @click="handleEnp(item.id)">
          <!-- <div v-if="item.id != 0" :class="item.pay_mode == '2' ? 'enp-item-status-active' : 'enp-item-status'">{{item.pay_mode == '2' ? '后' : '预'}}</div> -->
          <div class="enp-item-name" :title="item.name">{{ item.id }}·{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="right-wrap">
      <div class="query-wrap">
        <div class="qjc-flex">
          <el-select v-model="packageVal" class="query-package" @change="handleChangeSelect">
            <el-option v-for="item in packageList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <div class="query-date">
            <div class="query-date-label">{{ $t('table.t11') }}</div>
            <el-date-picker v-model="searchDate" type="daterange" value-format="timestamp" range-separator="~"
              :start-placeholder="$t('table.t12')" :end-placeholder="$t('table.t13')" @change="searchDateChange">
            </el-date-picker>
          </div>
        </div>
        <el-button class="query-btn" @click="handleQuery">{{ $t('table.t39') }}</el-button>
        <el-button class="query-btn" @click="handleRet">{{ $t('table.t40') }}</el-button>
      </div>
      <el-table :data="lists" style="width: 100%" row-key="id"
        :tree-props="{ children: 'haList', hasChildren: 'hasChildren' }" class="list">

        <!-- :height="mainHeight - 160 + 'px'" -->
        <template slot="empty">
          <div class="empty-wrap">
            <el-image class="empty-img" :src="require('@/assets/images/empty-history.png')"></el-image>
            <div class="empty-text">No data available</div>
          </div>
        </template>
        <el-table-column prop="id" :label="$t('table.t1')" fixed width="100" class-name="id">

        </el-table-column>
        <el-table-column :label="$t('table.t9')" fixed width="100" class-name="operate">
          <template slot-scope="scope">
            <span v-if="scope.row.old_id && scope.row.old_id != 0" :class="{ 'c-danger': scope.row.old_id > 0 }">{{
              scope.row.old_id > 0 ? $t('table.t38') : $t('table.t44') }}</span>
            <span v-if="scope.row.old_id == undefined">{{ $t('table.t44') }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="mobile" :label="$t('table.t2')" class-name="mobile">
          <template slot-scope="scope">
            {{ scope.row.mobile ? scope.row.mobile : '--' }}
          </template>
        </el-table-column>
        <!-- <el-table-column
					prop="yy_user_id"
					label="企业用户ID"
					class-name="yy_user_id"
				>
					<template slot-scope="scope">
						{{ scope.row.yy_user_id?scope.row.yy_user_id:'--' }}
					</template>
				</el-table-column> -->
        <el-table-column prop="goods_name" :label="$t('table.t3')" class-name="package">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 'AIZH'">[AI]Smart Health</span>
            <span v-else-if="scope.row.type == 'AIFB'">[AI]Smart Fracture-Risk</span>
            <span v-else-if="scope.row.type == 'AIBG'">[AI]VIP Health Risk Examination</span>
            <span v-else-if="scope.row.type == 'AIMB'">[AI]Postpartum Risk (Mother & Newborn)</span>
            <span v-else-if="scope.row.type == 'M22'">[AI]4-Hypers Analysis</span>
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="sex" :label="$t('table.t4')" class-name="sex">
          <template slot-scope="scope">
            {{ scope.row.sex == '1' ? $t('open.t14') : scope.row.sex == '2' ? $t('open.t15') : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="birth" :label="$t('table.t5')" class-name="birth">
          <template slot-scope="scope">
            {{ scope.row.birth ? scope.row.birth : '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="examdate" :label="$t('table.t6')" class-name="examdate">
          <template slot-scope="scope">
            {{ scope.row.examdate ? scope.row.examdate : '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="operator" :label="$t('table.t10')" class-name="operator">
          <template slot-scope="scope">
            {{ scope.row.operator ? scope.row.operator : '--' }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" :label="$t('table.t11')" class-name="created_at">
          <template slot-scope="scope">
            <span v-if="scope.row.created_at">{{ scope.row.created_at ? scope.row.created_at : '--' }}</span>
          </template>
        </el-table-column>

      </el-table>

      <!-- 分页 -->
      <el-pagination class="pagination qjc-texta-c" hide-on-single-page :current-page.sync="pager.page"
        :page-count="pager.pagecount" :page-size="pager.pagesize" :total="pager.count" @current-change="pageChange"
        prev-text="last" next-text="next" layout="total, prev, pager, next,jumper">
      </el-pagination>
    </div>


  </div>
</template>

<script>
import { adminRole, enterpriseList, getHistory, mainDataQuery, getUserGoodsList } from "@/utils/axios"
import { Base64 } from "js-base64"

export default {
  name: 'mainHistory',
  data () {
    return {
      submiting: false,//正在请求
      lists: [],
      mainHeight: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 60,
      companyList: [],
      pager: {
        count: 0,
        page: 1,// 默认第一页
        pagecount: 0,
        pagesize: 12//默认一页15条数据
      },
      enpId: '',
      packageList: [],
      packageVal: '',
      searchDate: '',//检索日期
      selectRowData: [],// 存储所选列表行数据
      adminId: ''
    }
  },
  mounted () {
    let adminId = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).issupper : '0'
    this.adminId = adminId
    // 获取企业列表
    if (adminId == 1) {
      enterpriseList({ act: '' }).then((res) => {
        if (res.code == 200) {
          const data = JSON.parse(Base64.decode(res.data))
          this.companyList = data;
          this.companyList.unshift({ id: '0', name: 'all' })
          this.enpId = data[0].id
          mainDataQuery({
            corp_id: this.enpId,
            startat: '',
            endat: '',
            goods_id: this.packageVal,
            page: this.pager.page,
            pagesize: this.pager.pagesize
          }).then(res => {
            // console.log(res);
            if (res.code == 200) {
              // 历史记录列表数据

              const data = JSON.parse(Base64.decode(res.data))
              let list = data.data;
              // this.lists = res.data.data;

              for (let i = 0; i < list.length; i++) {
                let obj = list[i].haList.pop()
                list[i].birth = obj.birth
                list[i].sex = obj.sex
                list[i].operator = obj.operator
              }
              this.lists = list
              // 分页
              var pager = data.pager;
              for (var key in pager) {
                pager[key] = parseInt(pager[key]);
              }
              this.pager = pager;
            } else {
              this.$myAlert({
                type: 'fail',
                content: res.msg
              });
            }
          });
        } else {
          this.$myAlert({
            type: 'fail',
            title: res.msg
          });
        }
      })
    } else {
      this.getHistoryList()
    }

    this.handleGoodsList()
  },
  methods: {
    handleEnp (id) {
      this.enpId = id
      this.packageVal = ''
      this.searchDate = ''
      this.pager.page = 1
      this.getHistoryList()
    },
    // 查询
    searchDateChange (daterange) {
      this.pager.page = 1
      // this.getHistoryList();
    },
    handleChangeSelect (val) {
      this.pager.page = 1
      // this.getHistoryList();
    },
    handleQuery () {
      this.pager.page = 1
      this.getHistoryList();
    },
    handleRet () {
      this.packageVal = ''
      this.searchDate = ''
      this.pager.page = 1
      this.getHistoryList();
    },
    pageChange (page) {
      this.pager.page = page;
      this.getHistoryList();
    },
    handleGoodsList () {
      getUserGoodsList().then((res) => {
        if (res.code == 200) {
          let goodsList = JSON.parse(window.atob(res.data)).goodsList
          let list = []
          let list2 = this.$t('goodsList')
          for (let i = 0; i < goodsList.length; i++) {
            for (let j = 0; j < list2.length; j++) {
              if (goodsList[i].goods_id == list2[j].goods_id) {
                goodsList[i].name = list2[j].name
              }
              if (goodsList[i].type == 'M22' && list2[j].type == 'M22') {
                goodsList[i].name = list2[j].name
              }
            }
          }
          goodsList.map((goods) => {
            list.push({
              id: goods.goods_id,
              name: goods.name
            })
          })


          list.unshift({
            id: '',
            name: 'All Packages'
          })
          this.packageList = list
        }
      })
    },
    // 获取列表
    getHistoryList () {
      // page 分页  act 操作类型
      let data = {}
      if (this.adminId) {
        data = {
          corp_id: this.enpId,
          startat: this.searchDate && this.searchDate.length > 0 ? this.searchDate[0] / 1000 : '',
          endat: this.searchDate && this.searchDate.length > 0 ? this.searchDate[1] / 1000 + 86399 : '',
          goods_id: this.packageVal,
          page: this.pager.page,
          pagesize: this.pager.pagesize
        }
      } else {
        data = {
          goods_id: this.packageVal,
          startat: this.searchDate && this.searchDate.length > 0 ? this.searchDate[0] / 1000 : '',
          endat: this.searchDate && this.searchDate.length > 0 ? this.searchDate[1] / 1000 + 86399 : '',
          page: this.pager.page,
          pagesize: this.pager.pagesize,
          lang: 3,
        }
      }
      mainDataQuery(data).then(res => {
        // console.log(res);
        if (res.code == 200) {
          // 历史记录列表数据
          const data = JSON.parse(Base64.decode(res.data))

          let list = data.data;

          for (let i = 0; i < list.length; i++) {
            let obj = list[i].haList.pop()
            list[i].birth = obj.birth
            list[i].sex = obj.sex
            list[i].operator = obj.operator
          }
          this.lists = list
          // 分页
          var pager = data.pager;
          for (var key in pager) {
            pager[key] = parseInt(pager[key]);
          }
          this.pager = pager;
        } else {
          this.$myAlert({
            type: 'fail',
            content: res.msg
          });
        }
      });
    },
  }
}
</script>

<style scoped>
.right-wrap>>>.el-table th {
  background-color: #F2F4FA;
}

.right-wrap>>>.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 380px;
}

.right-wrap>>>.el-date-editor .el-range-separator {
  color: #DBDDE6;
}

.right-wrap>>>thead th {
  color: #40444D;
  font-size: 14px;
  font-weight: 500;
  background-color: #F2F4FA;
}
</style>
<style lang="scss" scoped>
.role {
  display: flex;
  // padding: 24px;
  width: 100%;
  height: 100%;
  // border: 12px solid #F2F4FA;

  .left-menus {
    min-width: 170px;
    width: 170px;
    padding: 0 24px 30px;
    border-right: 1px solid #E5E8F5;
    background-color: #ffffff;
    overflow-y: auto;

    .add-action {
      display: flex;
      align-items: center;
      cursor: pointer;

      .add-action-img {
        display: flex;
        align-items: center;
        align-items: center;
        width: 24px;
        height: 24px;
        background-color: #6883FB;
        border-radius: 50%;

        &::after {
          content: "+";
          width: 100%;
          font-size: 20px;
          color: #fff;
          text-align: center;
          margin-bottom: 4px;
        }
      }

      .add-action-text {
        font-size: 14px;
        color: #6883FB;
        margin-left: 8px;
      }
    }

    .left-list {
      padding-top: 20px;
    }

    .left-enp-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 0;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #525866;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: #7A92FB;
      }
    }

    .left-enp-item-active {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 0;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #6883FB;
      cursor: pointer;
    }

    .enp-item-status {
      width: 20px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FF5219;
      background-color: #FDE8E2;
      margin-right: 4px;
      padding-top: 1px;
      border-radius: 50%;
      text-align: center;
    }

    .enp-item-name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .enp-item-status-active {
      width: 20px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6883FB;
      background-color: #DCE3FF;
      margin-right: 4px;
      padding-top: 1px;
      border-radius: 50%;
      text-align: center;
    }
  }

  .right-wrap {
    position: relative;
    min-width: 1048px;
    height: 100%;
    flex: 1;
    padding: 28px 24px;
    overflow: auto;

    .query-wrap {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin-bottom: 16px;

      .query-package {
        width: 226px;
      }
    }

    .c-danger {
      color: #FF4C52;
    }

    .query-date {
      display: flex;
      align-items: center;
      margin-left: 32px;

      .query-date-label {
        margin-right: 10px;
      }
    }

    .query-btn {
      width: 72px;
      height: 36px;
      line-height: 36px;
      font-size: 15px;
      padding: 0;
      margin-left: 16px;
      border: none;
      color: #fff;
    }

    .el-button:active {
      background-color: #5B73DC;
    }
  }

  .foot-pagination {
    // position: absolute;
    // bottom: 0;
    width: 96%;
    padding-bottom: 20px;
    // height: 80px;
  }

  .pagination {
    width: 100%;
    margin-top: 8px;
    text-align: center;
  }

  .empty-wrap {
    padding: 100px 0;
  }

  .empty-img {
    width: 338px;
    height: 218px;
  }

}
</style>
